import React from "react";

function Self_driving_Car() {
  return (
    <div className="self_driving_car-container">
      <section class="py-3 py-md-5" style={{ textAlign: "center" }}>
        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <h2 class="display-3 fw-bold lh-1">
                  Self Driving Cars / Autonomous Vehicles
                </h2>
                <p class="text-secondary fs-4 mb-2">
                  Self-driving cars & self-driving vehicles
                </p>
                <hr class="w-25 mx-auto ms-md-0 mb-4 text-secondary" />
                <h3>Articles</h3>
                <ul class="list-group">
                  <li class="list-group-item">
                    <a href="/vehicular-pollution">
                      Impact of vehicular pollution on the environment
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Self_driving_Car;
