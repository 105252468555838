import React from "react";

function Tcs_key_concepts() {
  return (
    <div>
      <section class="py-3 py-md-5" style={{ textAlign: "center" }}>
        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <h2 class="display-3 fw-bold lh-1">
                  Theoretical Computer Science Concepts
                </h2>

                <ul class="list-group">
                <li class="list-group-item">
                    <a href="/tcs-intro">what is TCS</a>
                  </li>
                  <li class="list-group-item">
                    <a href="/tcsvsai">TCS vs AI</a>
                  </li>
                  
                  {/* <li class="list-group-item">
                    <a href="/integer-facterization">Integer Factorization</a>
                  </li> */}
                </ul>

                <p>Thank you</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Tcs_key_concepts;
