import React from "react";

function Contact() {
  return (
<div>
  contact
</div>
  );
}

export default Contact;
