import React from "react";

function Obituary() {
  return (
    <div className="obituary-continer">
      <section class="py-3 py-md-5">
        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <h2 class="display-3 fw-bold lh-1">Obituary</h2>
                <p class="text-secondary fs-4 mb-2"></p>
                <p class="text-secondary fs-4 mb-2">Death is inevitable!</p>
                <hr class="w-25 mx-auto ms-md-0 mb-4 text-secondary" />
                <h3>Death</h3>
                <p style={{ textAlign: "left" }}>
                  We know very less or none about Death, this is one of the
                  biggest reason not to understand Life. You can not understand
                  right without outlining wrong, true without false and good
                  without bad. Mysteries of death sparks piculier curiosity and
                  fascination in me.
                </p>
                <h3>More</h3>
                <p>
                  Choosing kind of Death is my birthright. So I am here to claim
                  it with my own pleasure. I am to choosing to engineer the
                  world's hardest problems which will leave the profound legacy
                  behind me in the field of technology and business innovation.
                </p>
                <p>
                  Inventing wealth is not an option, it is hard requirement for
                  claiming my birthright. My strong curiosity toward knowledge
                  is the biggest advantage and sign to meet with something too
                  precious to comprehend linguistically.
                </p>
                <p>
                  Eliminating Engineered product and Technological advancement
                  form the our life, throws us back to Stone age where the human
                  civilization started. This gives me the sense of thrill and
                  importance of engineering knowledge and technology in our
                  life.
                </p>
                <p>
                  Money is illusion. Money is not earned, it is exchanged with
                  needs, Fears and aspirations. Money is system not physical but
                  mental.
                </p>

                <h3>Obituary</h3>
                <p style={{ textAlign: "left" }}>
                  It is with profound sadness that I myself taking pleasure to
                  announce the Death of my beloved Me, Gaurav Gautam Shakya,
                  World renowned Billionaire Businessman, Technologist who left
                  the profound immutable legacy behind in the history of
                  Technology and Business innovation. His Contribution to
                  Technology and Engineering is unforgettable specific to
                  Technology and innovation in Healthcare system of India.
                  Lastly, I want to prostrate to my parents for making me
                  existing. I wanna say Goodbye to everyone with whom I shared
                  love of life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Obituary;
