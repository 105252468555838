import grvPhoto from "../../assets/grvPhoto.jpeg";
import "./Home.css";
import linkedin from "../../assets/linkedin-in-brands-solid.svg";
import github from "../../assets/github-brands-solid.svg";
import twitter from "../../assets/twitter-brands-solid.svg";

function Home() {
  return (
    <div className="about-continer">
      <section class="py-3 py-md-5">
        <div class="container mb-4 mb-md-5" style={{ textAlign: "left" }}>
          <div class="row justify-content-md-center">
            <div class="col-12 col-md-10 col-xxl-8">
              <img
                height="300px"
                width="300px"
                class="img-fluid rounded shadow"
                loading="lazy"
                src={grvPhoto}
                alt="About 3"
              />
            </div>
          </div>
        </div>

        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <div className="social-media-links">
                  <ul >
                    <li>
                      <a
                        href="https://www.linkedin.com/in/gaurav0563/"
                        target="_blank"
                      >
                        <img
                          style={{ width: "20px", height: "20px" }}
                          src={linkedin}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://github.com/grv0563" target="_blank">
                        <img
                          style={{ width: "20px", height: "20px",}}
                          src={github}
                          
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://x.com/grv0563" target="_blank">
                        <img
                          style={{ width: "20px", height: "20px" }}
                          src={twitter}
                        />
                      </a>
                    </li>
                  </ul>
                </div>

                <h2 class="display-3 fw-bold lh-1">Gaurav Gautam Shakya</h2>

                <p
                  style={{ textAlign: "left" }}
                  class="text-secondary fs-4 mb-2"
                  className="quote"
                >
                  Ambitious and Aggressive Engineer to solve some of the World's
                  hardest problems.
                </p>
                <hr class="w-25 mx-auto ms-md-0 mb-4 text-secondary" />
                <p className="quote">
                  {" "}
                  “Life shrinks or expands in proportion to one's courage.”
                  You're probably not confident when you have little or no
                  experience with something, so you need courage.
                </p>
                <p className="quote" style={{ textAlign: "right" }}> - Anais Nin</p>
                <p  className="quote">
                  We make ourselves no promises, but we cherish the hope that
                  the unobstructed pursuit of useless knowledge will prove to
                  have consequences in the future as in the past” … “An
                  institution which sets free successive generations of human
                  souls is amply justified whether or not this graduate or that
                  makes a so-called useful contribution to human knowledge. A
                  poem, a symphony, a painting, a mathematical truth, a new
                  scientific fact, all bear in themselves all the justification
                  that universities, colleges, and institutes of research need
                  or require”
                </p>
                <p className="quote" style={{ textAlign: "right" }}>
                  {" "}
                  - Abraham Flexner, The Usefulness of Useless Knowledge, 1939.
                </p>
                <h3 style={{ backgroundColor: "black", color: "white" }}>
                  Who am I ?
                </h3>
                <p>
                  That's what usually I ask myself. But for now, My name is
                  Gaurav Gautam Shakya and my age is
                </p>
                <h2 id="age">23</h2>
                <p>
                  . I am from small Villege named Nagla Kunwar Kharbandi nagla
                  Wazir in city Farrukhabad, Uttar Predesh, INDIA. I am an
                  Engineer( but Electrical Engineer by Academics). Working as IT
                  Infrastructure observability Engineer at HCLTECH since 5th Oct
                  2023.Till Now I have not achieved much.
                </p>
                <hr />
                <p>
                  I believe that Working on Artificial General intelligence is
                  the most rewarding career for me. I am always interested in
                  Physics but went to engineering College. where I was exposed
                  to computer Hardware and software stuff. I fall in love with
                  the Mind-bending Complexity of Computer systems. Then I was
                  intrigued by the question "Why are engineers striving for
                  building such complex systems and Why businesses and spending
                  such big money on these systems".
                </p>
                <p>
                  Now I can feel the impact of these complex engineered systems
                  which empowers the individuals, SMBs, Big Business and even
                  some of the world's largest economies.
                </p>
                <p>
                  I am very impressed by the people who ask strategically broad
                  questions which give me food for thought. Asking or listening
                  to broad and unconventional questions seems stupid or
                  unnecessary for the first time because of limited resources
                  but they are crucial for setting the vision and existential
                  story of a company.
                </p>
                <p>
                  {" "}
                  I am looking for someone who is constantly striving to grasp
                  the big picture of company, industry or Economic opportunities
                  over the course of time.
                </p>

                {/* <h3 style={{ backgroundColor: "black", color: "white" }}>
                  Latest and hot In Technology and Business
                </h3> */}
                <h3>Projects</h3>
                <ul>
                  <li>
                    <a href="https://staging.grvnotes.com/">
                      HealthChain - Patient centric Intelligent QR Code Based
                      Decentralized EHR System
                    </a>
                  </li>
                  <li>
                    <a href="https://synapticagi.com/">
                      SynaptiAGI - Artificial Super Intelligence
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
