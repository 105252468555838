import React from "react";

const Download = () => {
    const onButtonClick = () => {
        const pdfUrl = "avatar.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "avatar.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <>
            <left>
                
                <button type="button" class="btn btn-primary" onClick={onButtonClick}>
                    Download PDF
                </button>
            </left>
        </>
    );
};

export default Download;