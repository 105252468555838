import React from "react";
import "./PublicHealthHighlight.css";
function PublicHealthHighlight() {
  return (
    <div className="obituary-continer">
      <section class="py-3 py-md-5" style={{ textAlign: "center" }}>
        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <h2 class="display-3 fw-bold lh-1">Health Technology</h2>
                <p class="text-secondary fs-4 mb-2"></p>
                <p class="text-secondary fs-4 mb-2">Indian HealthCare System</p>
                <hr class="w-25 mx-auto ms-md-0 mb-4 text-secondary" />
                <h3>Highlights of Public Health in India</h3>
                <p style={{ textAlign: "left" }}>
                  We all living in the age of rapid Globalization. Globalization
                  has several opportunities such as Economic growth, efficient
                  markets, increased global cooperation and spread of technology
                  and innovation across world. Rapid increase in globalization
                  starting to become significant threat to Public Health over
                  the years. Non Communicable Diseases(NCD)(also called as
                  <a href="https://www.iomcworld.org/medical-journals/lifestyle-disorders-33826.html#:~:text=Lifestyle%20diseases%20are%20defined%20as%20diseases%20linked%20with%20the%20way%20people%20live%20their%20life.%20These%20are%20non%2Dcommunicable%20diseases">
                    Lifestyle Disease{" "}
                  </a>{" "}
                  ) are the major threat to the Public Health. Some bullet
                  points form data published by WHO on 16 September 2023.
                </p>
                <ul class="list-group">
                  <li class="list-group-item">
                    <a href="https://www.who.int/news-room/fact-sheets/detail/noncommunicable-diseases#:~:text=Noncommunicable%20diseases%20(NCDs)%20kill%2041%20million%20people%20each%20year%2C%20equivalent%20to%2074%25%20of%20all%20deaths%20globally.">
                      Non Communicable diseases (NCDs) kill 41 million people
                      each year, equivalent to 74% of all deaths globally.
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="https://www.who.int/news-room/fact-sheets/detail/noncommunicable-diseases#:~:text=Of%20all%20NCD%20deaths%2C%2077%25%20are%20in%20low%2D%20and%20middle%2Dincome%20countries.">
                      Of all NCD deaths, 77% are in low- and middle-income
                      countries.
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="https://www.wbhealth.gov.in/NCD/#:~:text=In%20India%2C%20nearly%205.8%20million%20people%20(WHO%20report%2C%202015)%20die%20from%20NCDs%20(heart%20and%20lung%20diseases%2C%20stroke%2C%20cancer%20and%20diabetes)%20every%20year%20or%20in%20other%20words%201%20in%204%20Indians%20has%20a%20risk%20of%20dying%20from%20an%20NCD%20before%20they%20reach%20the%20age%20of%2070">
                      In India, nearly 5.8 million people (WHO report, 2015) die
                      from NCDs (heart and lung diseases, stroke, cancer and
                      diabetes) every year or in other words 1 in 4 Indians has
                      a risk of dying from an NCD before they reach the age of
                      70.
                    </a>
                  </li>
                </ul>
                <p>
                  According to the study report{" "}
                  <a href="https://pib.gov.in/PressReleaseIframePage.aspx?PRID=1796435#:~:text=According%20to%20the,tobacco%20and%20alcohol">
                    “India: Health of the Nation's States”{" "}
                  </a>{" "}
                  - The India State-Level Disease Burden Initiative in 2017 by
                  Indian Council of Medical Research (ICMR), it is estimated
                  that the proportion of deaths due to Non-Communicable Diseases
                  (NCDs) in India have increased from 37.9% in 1990 to 61.8% in
                  2016.
                </p>
                <p>
                  <a href="https://ncdalliance.org/why-ncds/NCDs#:~:text=NCDs%20(noncommunicable%20diseases)%20are%20the%20%231%20cause%20of%20death%20and%20disability%20worldwide%2C%20accounting%20for%2074%25%20of%20all%20deaths%20and%20more%20than%20three%20out%20of%20four%20years%20lived%20with%20a%20disability.">
                    {" "}
                    Important Study Bullet point by NCD Alliance Organization{" "}
                  </a>
                  - NCDs (Non communicable diseases) are the #1 cause of death
                  and disability worldwide, accounting for 74% of all deaths and
                  more than three out of four years lived with a disability.
                </p>
                <p>
                  An estimated 80% of NCDs are preventable. They are driven by
                  modifiable risk factors including tobacco use, unhealthy diet,
                  physical inactivity, harmful use of alcohol, and air
                  pollution.
                </p>

                <p>Signing off</p>
                <p>Gaurav Gautam Shakya</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PublicHealthHighlight;
