import React from "react";
import "./HealthTech.css";
function HealthTech() {
  return (
    <div className="obituary-continer">
      <section class="py-3 py-md-5" style={{ textAlign: "center" }}>
        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <h2 class="display-3 fw-bold lh-1">Health Technology</h2>
                <p class="text-secondary fs-4 mb-2"></p>
                <p class="text-secondary fs-4 mb-2">Indian HealthCare System</p>
                <hr class="w-25 mx-auto ms-md-0 mb-4 text-secondary" />
                <h3>Articles</h3>
                <ul class="list-group">
                  <li class="list-group-item">
                    <a href="/publichealthhighlight">
                      Highlights of Public Health in India
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HealthTech;
