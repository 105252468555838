import React from "react";

function Blockchain_and_AI() {
  return (
    <div className="obituary-continer">
      <section class="py-3 py-md-5">
        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-left">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <h2 class="display-3 fw-bold lh-1">Emerging Tech</h2>
                <p class="text-secondary fs-4 mb-2"></p>
                <p class="text-secondary fs-4 mb-2">
                  Blockchain, Cloud Computing and Artificial Intellignce
                </p>
                <hr class="w-25 mx-auto ms-md-0 mb-4 text-secondary" />
                <h3>Blockchain Technology</h3>
                <ul class="list-group">
                  <li class="list-group-item">
                    <a href="/blockchain">
                      Value proposition of Blockchain and Web 3.0
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="#">
                      Resource for Blockchain Technology and Web 3.0
                    </a>
                  </li>
                </ul>

                <h3>Artificial Intellignce</h3>
                <ul class="list-group">
                  <li class="list-group-item">
                    <a href="/ai-key-concepts">
                      Key Concepts
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="/ai">
                      How Deep Learning and ML Model Trained : Brief
                      Mathematical Perspective
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="#">
                      Unsupervised Machine Learning and Deep Learning
                    </a>
                  </li>
                </ul>
                <h3>Cloud Computing</h3>
                <ul class="list-group">
                  <li class="list-group-item">
                    <a href="/cloud-intro">Basics of Cloud computing</a>
                  </li>
                  {/* <li class="list-group-item">
                    <a href="#">
                      Unsupervised Machine Learning and Deep Learning
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blockchain_and_AI;
