import React from "react";

function Tcs() {
  return (
    <div>
      <section class="py-3 py-md-5" style={{ textAlign: "center" }}>
        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <h2 class="display-3 fw-bold lh-1">
                  Theoretical Computer Science
                </h2>
                <p class="text-secondary fs-4 mb-2"></p>
                <h3>Articles</h3>
                <ul class="list-group">
                  <li class="list-group-item">
                    <a href="/tcs-concept">Key Concept in Theoretical Computer Science </a>
                  </li>
                  <li class="list-group-item">
                    <a href="#">Theory of Computation(TOC)</a>
                  </li>
                  <li class="list-group-item">
                    <a href="#">Algorithms and Data Strucure</a>
                  </li>
                  <li class="list-group-item">
                    <a href="#">Parallel and Distributed Computation</a>
                  </li>
                  <li class="list-group-item">
                    <a href="#">Probabilistic Computation</a>
                  </li>
                  <li class="list-group-item">
                    <a href="#">Quantum Computation</a>
                  </li>
                  <li class="list-group-item">
                    <a href="#">Information Theory</a>
                  </li>
                  <li class="list-group-item">
                    <a href="#">Cryptography</a>
                  </li>
                  <li class="list-group-item">
                    <a href="#">Machine Learning</a>
                  </li>
                </ul>

                <p style={{ marginTop: "20px" }}>
                  <a href="#">Next</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Tcs;
