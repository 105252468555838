import React from "react";
import grvPhoto from "../../assets/grvPhoto.jpeg";
import Download from "../learn/Download";

function About() {
  return (
    <div className="about-continer">
      <section class="py-3 py-md-5">
        <div class="container mb-4 mb-md-5" style={{ textAlign: "left" }}>
          <div class="row justify-content-md-center">
            <div class="col-12 col-md-10 col-xxl-8">
              <img
                height="300px"
                width="300px"
                class="img-fluid rounded shadow"
                loading="lazy"
                src={grvPhoto}
                alt="About 3"
              />
            </div>
          </div>
        </div>

        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <h2 class="display-3 fw-bold lh-1">Gaurav Gautam Shakya</h2>
                <p class="text-secondary fs-4 mb-2">
                  An Ambitious and Aggressive Engineer
                </p>
                <hr class="w-25 mx-auto ms-md-0 mb-4 text-secondary" />
                <h3>Desires and Ambitions</h3>
                <p>
                  I am Ambitious to engineer some of the World's hardest
                  problems in Engineering, Healthcare and Science.
                </p>
                <p>
                  My Ultimate Desire is to Build and run at least one fortune
                  500 Technology Company.
                </p>
                <p>
                  Engineering Intelligence whether in General or super is most
                  fascination career opation to me.
                </p>
                <h3>Career and Interests</h3>
                <p>Entrepreneurship</p>
                <p>Health Technology</p>
                <p>Self Driving Cars/Autonomous Vehicles</p>
                <p>Artificial Super Intelligence</p>
                <p>
                  Distributed System - Especially Decentralized Systems like
                  Blockchain
                </p>

                <h3>Immediate Action Direction</h3>
                <p>
                  Reducing friction among healthcare actors and entry barrier
                  for startups. Driving Indian healthcare System towards Patient
                  Centric Preventive Care.
                </p>

                <h3>
                   Project Idea - Patient centric Intelligent QR Code Based Decentralized EHR
                  System
                </h3>
                
                <p>
                  I am proposing the QR based Decentralized EHR System. Where
                  Patient's Health information is owned by himself. Patient can
                  register by himself or can be done by provider (e.g. doctor or
                  Hospital staff) When Patient visit to Provider (e.g. Doctor),
                  Doctor scans the Patient owned QR code. Instantly, Doctor
                  (already registered on portal) have copy inserted in his
                  separate EMR portal (provide by us or other vendor) with
                  editable prefilled forms (Doctor can change as per needs) with
                  general information of patient. Also doctor has access to all
                  the patient history with AI integrated tools for tasks such as
                  Health History Summarization with implicit citation from
                  summarized sources such as previous prescriptions medical
                  tests etc.., finding the latest Medical Research Journals
                  based on the Health history of Patient or doctor's explicit
                  comment for search query to address the exceptional situations
                  and other Analytics/Big data tool for extraction insight for
                  business as well as Medical Research.
                </p>
                <p><a href="https://gaurav-book-store.gitbook.io/nextgen-ehr">more</a></p>
              </div>
            </div>
            <div class="col-12 order-md-0 col-md-4 col-xxl-4">
              <div class="text-center text-md-start me-md-3 me-xl-5">
                <p class="mb-4">
                  <span class="d-block display-6 lh-1">1+</span>
                  <span class="fs-4 text-secondary">years of experience</span>
                </p>
                <div class="d-grid">
                  <button class="btn btn-primary btn-lg" type="button">
                    <a
                      href="https://www.linkedin.com/in/gaurav0563/"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      Contact
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
