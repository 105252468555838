import React from "react";
import "./Index.css"
function Index() {
  return (
    <div>
      <section class="py-3 py-md-5" style={{ textAlign: "center" }}>
        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <p style={{backgroundColor:"ButtonShadow", color:"Highlight",textAlign:"center"}}>Book is UnderConstruction !</p>
                <h2 class="display-3 fw-bold lh-1">
                  Alien's Research{" "}
                  <span style={{ fontSize: "24px", fontFamily: "inherit" }}>
                    on Humanity on Earth
                  </span>{" "}
                </h2>
                <p class="text-secondary fs-4 mb-2"></p>
                <p class="text-secondary fs-4 mb-2">
                  We, Human are pretty advanced, only species who managed to
                  develop Language, Technology, and complex social organization
                  and has no competition on earth !
                </p>
                <hr class="w-25 mx-auto ms-md-0 mb-4 text-secondary" />
                <h3>Purpose</h3>
                <p style={{ textAlign: "left" }}>
                  We know very less or none about Death, this is one of the
                  biggest reason not to understand Life. You can not understand
                  right without outlining wrong, true without false and good
                  without bad. Mysteries of death sparks piculier curiosity and
                  fascination in me.
                </p>

                <h3>Why</h3>
                <p style={{ textAlign: "left" }}>
                  
                </p>
                <p>Chapters</p>

                <ol class="list-group list-group-numbered">
                  <li class="list-group-item"><a target="_blank" href="/preface">Preface</a></li>
                  <li class="list-group-item"><a target="_blank" href="/intro">Introduction</a></li>
                  <li class="list-group-item">
                    <a target="_blank" href="/accident">One Accident, Invention or Discovery or what?</a>
                  </li>
                  <li class="list-group-item">
                    <a target="_blank" href="/problem1">Type 1 - Problems: Intra-group Problems - People &
                    Governance</a>
                  </li>
                  <li class="list-group-item">
                   <a target="_blank" href="/problem2"> Type 2 - Problems: Intra-group Problems -
                    Internationalization</a>
                  </li>
                  <li class="list-group-item">
                    <a target="_blank" href="/problem3">Type 3 - Problems: Cosmological & Environmental Wonders -
                    The Religion</a>
                  </li>
                  <li class="list-group-item">
                   <a target="_blank" href="/problem4"> Type 4 - Problems: Hunam Problems - Agents of
                    vulnerabilities, Love and Compassion</a>
                  </li>
                  <li class="list-group-item">
                   <a target="_blank" href="/abstraction"> Abstraction in Human problem space in current state of human
                    regime</a>
                  </li>
                  <li class="list-group-item"><a target="_blank" href="/conclusion">Conclusion</a></li>
                  <li class="list-group-item">
                    <a target="_blank" href="/future">Future and Current trend of Human Engineering and Evolution</a>
                  </li>
                </ol>
                <p style={{textAlign:"center",marginTop:"10px"}}>I ❤️ simple things not easy Ones !</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Index;
