import React from 'react'

function Halting_problems() {
  return (
    <div>
      <section class="py-3 py-md-5" style={{ textAlign: "center" }}>
        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <h2 class="display-3 fw-bold lh-1">
                Halting Problem
                </h2>
                <hr class="w-25 mx-auto ms-md-0 mb-4 text-secondary" />
                <p class="text-secondary fs-4 mb-2"></p>

                <p>Thank you</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Halting_problems