import React from "react";

function Blockchain() {
  return (
    <div className="blockchain-container">
      <section class="py-3 py-md-5" style={{ textAlign: "center" }}>
        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <h2 class="display-3 fw-bold lh-1">Blockchain Technology</h2>
                <p class="text-secondary fs-4 mb-2"></p>
                <p class="text-secondary fs-4 mb-2">
                  Value proposition of Blockchain and Web 3.0
                </p>
                <hr class="w-25 mx-auto ms-md-0 mb-4 text-secondary" />
                <h3>Introduction</h3>
                <p>
                  Generally, Blockchain and cryptocurrencies are often
                  associated the Principle of decentralization.
                </p>
                <h3>What is Blockchain?</h3>
                <p>
                  Blockchain is a single, distributed, immutable, write-only
                  ledger of transactions that is updated when multiple,
                  decentralised actors achieve consensus on the validity of new
                  entries by participants.
                </p>
                <p>
                  Blockchain is immutable ledger controlled by community
                  participants.
                </p>
                <h3>Characteristics of Blockchain</h3>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">Cryptography</li>
                  <li class="list-group-item">Immutability</li>
                  <li class="list-group-item">Peer-to-peer network</li>
                  <li class="list-group-item">
                    Distributed Ledger as database
                  </li>
                </ol>
                <h3>Blockchain Platforms</h3>
                <h5>Public Blockchains(open source)</h5>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">Bitcoin</li>
                  <li class="list-group-item">Ethereum</li>
                  <li class="list-group-item">Neo</li>
                </ol>
                <h5>
                  Private Blockchains or Enterprise Blockchain (opensource)
                </h5>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">
                    Hyperledger by Linux Foundation
                  </li>
                  <li class="list-group-item">EOS</li>
                  <li class="list-group-item">Corda</li>
                  <li class="list-group-item">Quorum</li>
                  <li class="list-group-item">Multichain</li>
                </ol>

                <h5>Blockchain solution on the Public clouds</h5>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">
                    Azure Blockchain as Service(Baas)
                  </li>
                  <li class="list-group-item">GCP Blockchain Node Engine</li>
                  <li class="list-group-item">
                    Amazon Quantum Ledger Database and Amazon Managed Blockchain
                    Service
                  </li>
                  <li class="list-group-item">
                    IBM's Blockchain solutions Build upon Opensource Hyperledger
                    Blockchain
                  </li>
                </ol>

                <h3>Brief History of Blockchain and WEB 3.0</h3>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">
                    {" "}
                    WEB 1.0 -- only Readable Webpages
                  </li>
                  <li class="list-group-item">
                    WEB 2.0 -- due to CSS and Javascript, user can read, write
                    and interact with web
                  </li>
                  <li class="list-group-item">
                    WEB 3.0 -- It is hard to define web3.0 because, it involves
                    in the philosophical construct like Decentralization of Web,
                    Democratic web, Distribution of power and control over data
                    and implication with distribution of finance, Eliminating
                    intermediary in transaction and many more concerns like
                    privacy and regulatory compliance issues.
                  </li>
                </ol>
                <p>Web 3.0 is bundle of next generation technologies.</p>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">Blockchain</li>
                  <li class="list-group-item">AI and Machine learning</li>
                  <li class="list-group-item">Big Data</li>
                </ol>
                <h3>Potential Business Use Cases</h3>
                <ol className="business-case" class="list-group list-group-numbered">
                  <li   class="list-group-item">
                    {" "}
                    Building Trustless and Permission less System
                  </li>
                  <p>
                    Immutability nature of blockchain gives us mechanism to
                    build trust and transparency among the stakeholders of
                    ecosystem of company. This trust and transparency reduces
                    the friction and help stakeholders to identify the process
                    waste and discrepancies of system. Blockchain can be used to
                    build relationships between business to customer, government
                    to citizens and regulator to regulated entity with
                    transparency.
                  </p>
                  <p>examples:</p>
                  <ol>
                    <li>
                      Building the trust among vendors, supplier and key
                      partners.
                    </li>
                    <li>
                      Automating the Business workflow/Company policies using
                      smart contracts.
                    </li>
                  </ol>
                  <li class="list-group-item">
                    Eliminating Intermediary and Business Process Streamlining
                  </li>
                  <p>
                    There are lot of Business models which relies on the trust
                    on 3rd party or intermediary(approver in Financial
                    transaction; BANK) . No business model is complete without
                    figuring out dependency on Vendors, Suppliers and Strategic
                    partners or alliances.
                  </p>
                  <p>
                    This reliance on the intermediary or vendors and supplier or
                    transaction approver institution (e.g Bank in case of
                    Finance) introduces lot of friction and inefficiency in the
                    Business Operations. It also poses the challenges to
                    transparency in company ecosystem and early detection of
                    critical issues.
                  </p>
                  <p>examples:</p>
                  <ol>
                    <li>
                      Eliminating the intermediary(Bank, financial institution)
                      in financial transaction.
                    </li>
                    <li>Streamlining Insurance Claim processing.</li>
                    <li>
                      Building trust between regulator institutions using smart
                      contracts.
                    </li>
                  </ol>

                  <li class="list-group-item">
                    Improving Explainability of AI/Machine learning Systems{" "}
                  </li>
                  <p>
                    Blockchain is one of potential solution for increasing the
                    explainability of Machine learning systems or AI models.
                  </p>
                  <p>References:</p>
                  <ol>
                    <li>
                      <a href="https://www.ibm.com/topics/blockchain-ai#:~:text=Blockchain%E2%80%99s%20digital%20record%20offers%20insight%20into%20the%20framework%20behind%20AI%20and%20the%20provenance%20of%20the%20data%20that%20it%20is%20using%2C%20addressing%20the%20challenge%20of%20explainable%20AI.%20This%20insight%20helps%20improve%20trust%20in%20data%20integrity%20and%20in%20the%20recommendations%20that%20AI%20provides.">
                        Blockchain and AI on IBM's Blogpost
                      </a>
                    </li>
                    <li>
                      <a href="https://www.researchgate.net/publication/336641970_Blockchain_for_explainable_and_trustworthy_artificial_intelligence/link/5da98ffd299bf111d4be460a/download">
                        ResearchGate Journal
                      </a>{" "}
                    </li>
                  </ol>
                  <li class="list-group-item">
                    Streamlining and Monitoring the Supply Chain in real time
                  </li>
                  <p>
                    Supply chain has many actor or intermediary from one end to
                    another( e.g Producer/Manufacturer, distributors or
                    wholesalers, retailers and customers or consumers)
                  </p>
                  <p>
                    {" "}
                    Maintaining coordination and crucial information flow among
                    the all the actors is crucial for efficient supply chain
                    operation. Blockchain can help company to establish the
                    trust and transparency to supervise and monitor the
                    end-to-end supply chain activity.
                  </p>
                  <p>
                    If you are in franchises business, then you can use Smart
                    Contracts to implement your brand policies, license and T&C
                    between franchisor and franchisee.
                  </p>
                  <li class="list-group-item">Fraud Prevention</li>
                  <p>
                    {" "}
                    Blockchain comes with inherent property of immutability and
                    transparency. This can reduce the Fraud in the system. If
                    still someone tries to do then it will easily reflect in
                    audit or incident investigation.{" "}
                  </p>
                  <li class="list-group-item">
                    Online Democratic Fair and audiable(system of proof)
                    Election Voting Platform
                  </li>
                  <p>
                    Due to the promises of immutability , decentralized,
                    transparent and secure nature of Blockchain gives direction
                    to build the digital national election platform.{" "}
                  </p>
                  <li class="list-group-item">
                    Online Democratic Fair and audiable(system of proof)
                    Election Voting Platform
                  </li>
                  <p>
                    Due to the promises of immutability , decentralized,
                    transparent and secure nature of Blockchain gives direction
                    to build the digital national election platform.{" "}
                  </p>
                  <p>Advantages:</p>
                  <ol>
                    <li>More control over election process</li>
                    <li>less administrative effort</li>
                    <li>More transparency</li>
                    <li>
                      Flexible and convenient for voter and election candidate
                    </li>
                    <li>less fraudulent activities</li>
                  </ol>
                  <li class="list-group-item">Digital Identity</li>
                  <p>
                    Blockchain is promising solution for creating digital
                    identities. e.g passport, Driver's license and national ID
                    and other's.{" "}
                  </p>
                  <p>
                    Before accessing anything on web, you need digital identity
                    like Gmail, Hotmail or other online/social media account
                    like facebook, instagram, linkedin or Github account. These
                    companies act as 3rd party to verify the identity of user of
                    web. In other words these identity provider are intermediary
                    in the web and user over the web.
                  </p>
                  <p>
                    User of web have to rely on the existence of these identity
                    providers and they have all the control over the user's
                    account data.
                  </p>
                  <p>Blockchain can be used to create decentralized Public Identity.</p>
                </ol>
                <p>Thank you</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blockchain;
