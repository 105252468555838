import "./App.css";
import PageRoutes from "./PageRoutes";
import Footer from "./components/Footer";
import NavigationBar from "./components/NavigationBar";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <NavigationBar />
        <PageRoutes />
       <Footer/>
      </header>
    </div>
  );
}

export default App;
