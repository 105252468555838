import React from "react";

function Unsolved() {
  return (
    <div>
      <section class="py-3 py-md-5" style={{ textAlign: "center" }}>
        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <h2 class="display-3 fw-bold lh-1">
                Unsolved Problems in Computer Science
                </h2>
                
                <ul class="list-group">
                  <li class="list-group-item">
                    <a href="/pvsnp">
                      P vs NP
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="/halting-problem">
                      Halting problem
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="/integer-facterization">
                      Integer Factorization
                    </a>
                  </li>
                </ul>

                <p>Thank you</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Unsolved;
