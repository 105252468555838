import React from "react";
import Download from "../Download";
import draw from "../../../assets/draw1.png";
import draw2 from "../../../assets/draw2.png";
import "./VehicularPollution.css"
function VehicularPollution() {
  return (
    <div className="obituary-continer">
      <section class="py-3 py-md-5">
        <div class="container overflow-hidden">
          <div class="row gy-2 gy-md-0 justify-content-xxl-center">
            <div class="col-12 order-md-1 col-md-8 col-xxl-6">
              <div class="text-center text-md-start">
                <h2 class="display-3 fw-bold lh-1">
                  Self Driving Cars / Autonomous Vehicles{" "}
                </h2>
                <p class="text-secondary fs-4 mb-2"></p>
                <p class="text-secondary fs-4 mb-2">
                  Impact of vehicular pollution on the environment
                </p>
                <Download />
                <hr class="w-25 mx-auto ms-md-0 mb-4 text-secondary" />
                <h3>Abstract</h3>
                <hr/>
                <p style={{ textAlign: "left" }}>
                  Vehicular pollution is one of the most pressing environmental
                  concerns of our time. Addressing this problem requires a
                  two-pronged approach. First, we need to develop technologies
                  that reduce vehicular emissions. Second, we should consider
                  replacing the internal combustion engine—the primary source of
                  vehicular pollution—with electric motors. However, the
                  automobile industry faces a significant challenge known as the
                  "battery charging problem," which is the limited range and
                  lengthy charging time of electric vehicles.
                </p>
                <p style={{ textAlign: "left" }}>
                  To address this challenge, I propose an innovative solution
                  that builds upon wireless charging technology. This solution
                  involves charging the vehicle's battery through a radio
                  (Electromagnetic waves) channel by radiating energy packets(
                  contains energy e.g 1-2 watt) from the source side to Sink
                  side ( vehicle). These sources and sink could communicate
                  using 4G or 5G cellular technology for the managing power flow
                  between source and sink. Each packet contributes to a larger
                  pool of energy on the vehicle's side.
                </p>
                <p style={{ textAlign: "left" }}>
                  To maximize the effectiveness of this technology, we can apply
                  power electronic circuits and advanced electrical engineering
                  techniques to the energy pool. This would enable us to drive
                  the electric motor of the vehicle with minimal energy loss,
                  making it a highly efficient and sustainable solution.
                  Ultimately, this wireless charging technology could
                  revolutionize the way we transfer electrical energy,
                  eliminating the need for physical charging stations and
                  enabling electric vehicles to operate for longer distances
                  without interruption.
                </p>

                <h3>Introduction</h3>
                <hr/>
                <p style={{ textAlign: "left" }}>
                  Vehicular pollution is a major environmental concern that
                  arises from the emissions generated by internal combustion
                  engines (ICEs) used in conventional gasoline and
                  diesel-powered vehicles. These emissions include harmful
                  pollutants such as carbon monoxide, nitrogen oxides,
                  particulate matter, and volatile organic compounds, which
                  contribute to air pollution and have negative impacts on human
                  health and the environment.
                </p>
                <p>
                  The increased use of electric vehicles (EVs) has been proposed
                  as a potential solution to mitigate the impact of vehicular
                  pollution on the environment. EVs use electric motors powered
                  by rechargeable batteries, which do not emit any pollutants
                  during operation. However, the environmental impact of EVs
                  depends on the source of electricity used to power them.
                </p>
                <p>
                  In this context, it is important to evaluate the potential
                  benefits and drawbacks of EVs in terms of their environmental
                  impact. This includes considering the upstream impacts
                  associated with battery production and electricity generation,
                  as well as the downstream impacts of battery disposal and
                  recycling. By doing so, we can better understand the overall
                  environmental impact of EVs compared to conventional ICE
                  vehicles, and identify strategies to minimize their impact on
                  the environment.
                </p>
                <p>
                  The efficiency of Internal combustion engine(IC) is normally
                  of the order of 30-35%. However, the efficiency of a normal 3
                  phase induction motor is the order of 80-90%. whereas 90%
                  efficiency is more common in case of medium induction motor
                  and in case of large induction motor efficiency can be up to
                  95%. If we do a comparative study on electric vehicles and IC
                  vehicles then it seems that EV’s are much more energy
                  efficient than IC engine vehicles. But if I look at the
                  market, EV's have not grown up as it seems by looking at its
                  advantages. What are the problems occuring which prevent the
                  EV’s to unlock its full potential.
                </p>
                <h3>
                  Problem Statement (Energy Transfer b/w power source and
                  vehicle)
                </h3>
                <hr/>
                <p>
                  Electric vehicles (EVs) offer a promising alternative to
                  traditional gasoline-powered cars, but they face two major
                  challenges: charging time and range. The amount of time it
                  takes to charge an EV and the distance it can travel on a
                  single charge are critical factors in determining their
                  practicality and adoption.
                </p>
                <p>
                  To overcome these challenges, we need to consider the energy
                  transfer between the EV and the power source. There are two
                  ways that energy transfer can occur: when the EV is stationary
                  and being charged, or when it is in motion and requires
                  charging on the go.
                </p>
                <p>
                  When the EV is stationary, it can be charged using a
                  conventional wired charger or a wireless charger. Both options
                  have their benefits and drawbacks, but they can be effective
                  in increasing the convenience and accessibility of EV
                  charging.
                </p>
                <p>
                  However, when the EV is in motion, it becomes much more
                  difficult to charge it. This is where mobile wireless charging
                  technology(MWCT) comes in. By installing charging pads on the
                  road or in parking spots, we can enable EVs to charge while
                  they are in motion. This could significantly increase the
                  range of EVs and make them more practical for long-distance
                  travel.
                </p>
                <p>
                  Overall, addressing the charging time and range issues in EVs
                  will require a combination of solutions, including improving
                  battery technology, expanding charging infrastructure, and
                  developing new charging technologies. With continued
                  investment and innovation, we can make EVs a viable and
                  attractive option for consumers.
                </p>
                <h3>Discussion</h3>
                <hr/>
                <p>
                  The technique of transferring energy from a power source to a
                  remote sink, known as MWCT, utilizes a radio channel to
                  transmit energy from the source to the sink. The source is
                  responsible for supplying the energy, while the sink can be
                  any device that requires charging, not limited to electric
                  vehicles. This can include cell phones, laptops, and any other
                  device that requires power.
                </p>
                <p>
                  The MWCT method works by transmitting DC or AC electrical
                  energy in the form of electromagnetic waves from the source to
                  the sink. Once the waves are received by the device in need of
                  charging, they are decoded and the actual DC or AC signals are
                  extracted. These signals are then used to charge the batteries
                  of the device.
                </p>
                <p>
                  The source produces small packets of energy, which contain the
                  electrical energy (AC or DC), and the authorized devices
                  within range will receive these packets. Once the packets are
                  received, they are decoded, and the original energy pulses are
                  extracted for charging the battery of the device.
                </p>
                <p>
                  This technology can greatly simplify the process of charging
                  devices, as it eliminates the need for cables and adapters.
                  MWCT can potentially improve accessibility to power for a
                  variety of devices, leading to more convenience and ease of
                  use for consumers. Overall, the potential applications of MWCT
                  are significant, and could lead to a more efficient and
                  streamlined approach to power management in our society.
                </p>
                <img src={draw} />
                <h3>Demonstration(Theoretical)</h3>
                <hr/>
                <p>
                  Electric vehicles are propelled by electric motors, which are
                  selected based on the mechanical load that they will need to
                  handle. The mechanical load of an electric vehicle includes
                  factors such as its weight, other mechanical components, and
                  the number of people who will be riding on the vehicle. The
                  selection of an appropriate electric motor is critical to
                  ensure that the vehicle can operate efficiently and safely
                  with the given load.
                </p>
                <h5>
                  Calculation of Power(watt or Joule/second) required by
                  Electric Vehicle:
                </h5>
                <p>
                  Weights of different vehicles shown in table 1.1 and Maximum
                  speed range and acceleration Time shown in Table 1.2.
                </p>
                <table class="table table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Index</th>
                      <th scope="col">Type of Vehicle</th>
                      <th scope="col">Weight(Kg)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Bike</td>
                      <td>200 - 250</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Scooter</td>
                      <td>110 - 150</td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>car</td>
                      <td>1200 - 2000</td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ textAlign: "center" }}>Table 1.1</p>
                <table class="table table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Index</th>
                      <th scope="col">Type of Vehicle</th>
                      <th scope="col">Maximum Speed Range(Km/hr)</th>
                      <th scope="col">Acceleration Time(seconds)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Bike(e.g Apache, bajaj pulsar, Royal Enfield etc)</td>
                      <td>100-150</td>
                      <td>7.28 - 16.62 (0-60 Km/hr)</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Car</td>
                      <td>200-350</td>
                      <td>6 - 8(0-60km/hr)</td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ textAlign: "center" }}>Table 1.2</p>
                <p>
                  Power required by Vehicle will we equal to change in Kinetic
                  Energy(½ M𝑉 ) of the Vehicle while accelerating.
                </p>
                <img style={{width:"-webkit-fill-available"}} src={draw2} />
                <table class="table table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Index</th>
                      <th scope="col">Type of Vehicle</th>
                      <th scope="col">
                        Power Required by EV(Watts per second) (𝑃𝑇))
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Bike</td>
                      <td>3815.62 - 1671.3474</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Car</td>
                      <td>27777.78 - 20833.3334</td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ textAlign: "center" }}>Table 1.3</p>
                <p>
                  Table 1.3 shows the power required by EV per second to
                  accelerate from 0 km/hr to 60 km/hr. While electric vehicles
                  (EVs) may not require acceleration during operation, they
                  still require a continuous supply of energy to overcome
                  external factors such as air resistance and other
                  environmental factors that can impede their motion.
                </p>
                <h3>IMPORTANT:</h3>
                <p>
                  To address the challenge of powering electric vehicles (EVs)
                  while they are in motion, an effective energy transfer system
                  that can supply the required energy per second is necessary.
                  Electromagnetic waves offer a promising solution for
                  transmitting energy wirelessly to EVs, as they can potentially
                  transfer power over a distance without the need for physical
                  contact. If we can develop a reliable and efficient energy
                  transfer system based on electromagnetic waves, we could
                  eliminate the need for frequent charging stops and make EVs a
                  more viable option for long-distance travel.
                </p>
                <p>
                  It might happen we could not transfer energy that is needed by
                  EV because the Motor used in EV has a rating in KW. But this
                  could charge small devices like cell phones and laptops etc
                  these devices have low power rating Batteries. E.g 5000mAh,
                  6000mAh .
                </p>
                <h3>Potential Application</h3>
                <hr/>
                <p>
                  The transfer of energy among devices through wireless means
                  has the potential to revolutionize several industries,
                  particularly in the realm of power management. With
                  applications in electric vehicle charging, cell phone
                  charging, and any device that operates on batteries, this
                  technique could greatly improve convenience and accessibility
                  for users. One sector that could particularly benefit from
                  this technology is the Internet of Things (IoT), where devices
                  rely heavily on power management to function efficiently.
                </p>
                <p>
                  One potential application of this technology is the Mobile
                  Wireless Cellphone Charger (MWCC), which could be installed in
                  homes and allow cell phones to connect wirelessly for
                  charging. This would eliminate the need for charging cables
                  and adapters, and would enable users to use their phones
                  freely while charging. Furthermore, the range of the MWCC
                  could be extended to cover an entire room or home, enabling
                  users to use their phones while moving around freely within
                  the charging range.
                </p>
                <p>
                  The potential applications of wireless charging technology are
                  vast, and could include the installation of chargers in public
                  areas such as malls, parking lots, and railway stations. This
                  would enable users to charge their devices on the go, leading
                  to more accessible and convenient usage of advanced technology
                  in our society. Overall, the potential benefits of wireless
                  energy transfer are significant, and could lead to a more
                  focused, accessible, and privileged life for all.
                </p>
                <h3>Conclusion</h3>
                <hr/>
                <p>
                  In conclusion, vehicular pollution is a major environmental
                  concern that arises from the emissions generated by internal
                  combustion engines (ICEs) used in conventional gasoline and
                  diesel-powered vehicles. Electric vehicles (EVs) have been
                  proposed as a potential solution to mitigate the impact of
                  vehicular pollution on the environment. However, EVs face two
                  major challenges: charging time and range. To address these
                  challenges, mobile wireless charging technology (MWCT) can be
                  used to transfer energy from the power source to the EV. This
                  technology uses a radio channel to transmit energy from the
                  source to the sink, which is the device in need of charging.
                  MWCT can potentially improve the practicality and adoption of
                  EVs by enabling them to charge while they are in motion. We
                  can make EVs a viable and attractive option for consumers,
                  ultimately reducing the impact of vehicular pollution on the
                  environment.
                </p>
                <h3>References</h3>
                <hr/>
                <p>
                  Sushmitha Ramireddy, Vineeth Reddy Ala, Ravishankar KVR, Arpan
                  Mehar (2021) "Acceleration and Deceleration Rates of Various
                  Vehicle Categories at Signalized Intersections in Mixed
                  Traffic Conditions,49(4), pp. 324–332, 2021.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VehicularPollution;
