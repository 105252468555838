import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavigationBar() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">Gaurav Gautam Shakya</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{textAlign:"center"}}>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="https://resume.grvnotes.com/index.html">Work</Nav.Link>
            <Nav.Link href="/obituary">Obituary</Nav.Link>
            <Nav.Link href="https://resume.grvnotes.com/contact.html">Contact</Nav.Link>
            <NavDropdown title="Learn" id="basic-nav-dropdown">
              <NavDropdown.Item href="/self-driving-cars">Self-driving Cars</NavDropdown.Item>
              <NavDropdown.Item href="/healthtech">
                Health Technology
              </NavDropdown.Item>
              <NavDropdown.Item href="/bc-and-ai">Emerging Tech</NavDropdown.Item>
              <NavDropdown.Item href="/tcs">Theoretical Computer Science</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/unsolved-problems">Unsolved Problems in Computer Science</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/bookindex">
              Book - Alien's Research on Humanity on Earth
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;